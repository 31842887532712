var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.params.tab == 'devices')?_c('div',[_c('page-component',{attrs:{"pageTitle":"Devices","headers":_vm.headers,"items":_vm.filteredDevices,"loadingData":_vm.loading,"buttons":[
      {
        text: '+ Add Kiosk/POS',
        to: { params: { form: 'AddEditForm', id: 'kioskPos' } },
      },
      {
        text: '+ Add Terminal',
        to: { params: { form: 'AddEditForm', id: 'terminal' } },
      },
    ]},scopedSlots:_vm._u([{key:"filters[0]",fn:function(){return [_c('custom-select',{attrs:{"items":_vm.deviceOptions,"label":"Devices","multiple":"","dense":""},model:{value:(_vm.selectedDeviceOptions),callback:function ($$v) {_vm.selectedDeviceOptions=$$v},expression:"selectedDeviceOptions"}})]},proxy:true},{key:`item.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:`item.status`,fn:function({ item }){return [(item.type === 'terminal')?_c('div',[(_vm.terminalStatusTitles[item.id])?_c('v-icon',{attrs:{"color":_vm.getTerminalStatusColorForTitle(_vm.terminalStatusTitles[item.id]),"title":_vm.terminalStatusTitles[item.id]}},[_vm._v(" mdi-circle ")]):_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","color":"grey","size":"20","width":"2"}}),_vm._v(" "+_vm._s(_vm.terminalStatusTitles[item.id] || "Loading status...")+" ")],1):_vm._e()]}},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatItemType(item))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('nxg-action-btn',{attrs:{"type":"delete","item-type":"DEVICE","item":item}})]}}],null,true)}),(_vm.$route.params.form == 'AddEditForm' && _vm.$route.params.id === 'kioskPos')?_c('kiosk-pos-form',{on:{"close":_vm.closeForm}}):_vm._e(),(_vm.$route.params.form == 'AddEditForm' && _vm.$route.params.id === 'terminal')?_c('terminal-form',{on:{"close":_vm.closeForm}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }