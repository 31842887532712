<template>
  <form-dialog
    visible
    title="Devices"
    :closeDisabled="closeDisabled"
    :awaitingResponse="awaitingResponse"
    :submitLabel="deviceCode ? 'Continue' : 'Generate'"
    :error="mainErrorMessage"
    @close="$emit('close')"
    @submit="submitHandle()"
  >
    <v-row class="mt-1">
      <v-col v-if="!deviceCode" cols="4">
        <v-select
          v-model="type"
          :items="types"
          item-text="name"
          item-value="value"
          outlined
          label="Type"
        />
      </v-col>
      <v-col v-if="type === 'stripeTerminal'">
        <v-text-field
          v-model="registrationCode"
          label="Registration Code"
          outlined
        />
      </v-col>
      <div v-if="type === 'squareTerminal'">
        <div v-if="!deviceCode">
          <v-col>
            <v-select
              v-model="squareRestaurantId"
              :items="squareRestaurants"
              item-text="name"
              item-value="value"
              outlined
              label="Restaurants"
            />
          </v-col>
        </div>
        <div v-else>
          <h3>
            "On your Square Terminal, tap Sign in > Use device code. Enter the code
            below. Then, tap Sign In. The code expires after 5 minutes. After you
            sign in, press continue to verify the connection."
          </h3>
          <h2 class="text-center mt-4">{{ deviceCode }}</h2>
          <h2 class="text-center mt-5">
            {{ pairingSquareTerminalMessage }}
          </h2>
        </div>
      </div>
      <v-col v-if="!deviceCode">
        <v-text-field v-model="terminalName" label="Terminal Name" outlined />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState, mapActions, mapGetters } from "vuex"
import GeneralMixins from "@/AuthenticatedContent/mixins"

export default {
  name: "add-edit-form",
  components: { FormDialog },
  mixins: [GeneralMixins],
  data() {
    return {
      closeDisabled: false,
      awaitingResponse: false,
      type: "",
      types: [
        { name: "Square", value: "squareTerminal" },
        { name: "Stripe", value: "stripeTerminal" },
      ],
      mainErrorMessage: "",
      squareRestaurantId: "",
      terminalName: "",
      deviceCode: "",
      deviceStatus: "UNPAIRED",
      registrationCode: "",
      pairingSquareTerminalMessage: "",
    }
  },
  computed: {
    ...mapState(["channels", "firebaseRefs"]),
    ...mapGetters(["getRestaurantName"]),
    squareRestaurants() {
      return this.channels
        .filter(channel => channel.name === "SquareRestaurant")
        .map(channel => {
          return {
            name: this.getRestaurantName(channel.restaurantData[0].restaurantId),
            value: channel.restaurantData[0].restaurantId,
          }
        })
    },
  },

  methods: {
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true

      if (this.type === "squareTerminal") {
        if (!this.deviceCode) {
          // Generate terminal code to login on Square terminal
          this.deviceCode = await this.createSquareDeviceCode(
            this.firebaseRefs.organization.id,
            this.squareRestaurantId,
            this.terminalName
          )
        } else if (this.deviceStatus === "UNPAIRED") {
          // HAVE DEVICE CODE BUT UNPAIRED

          let deviceCodes = await this.listSquareDevicesCodes(
            this.firebaseRefs.organization.id,
            this.squareRestaurantId
          )
          this.deviceStatus = deviceCodes.find(
            device => device.code === this.deviceCode
          ).status

          if (this.deviceStatus === "PAIRED") {
            this.pairingSquareTerminalMessage = "Your Square Terminal is now paired."
          } else {
            this.pairingSquareTerminalMessage =
              "Square Terminal is not paired, please try again."
          }
        } else {
          // HAVE DEVICE CODE AND PAIRED
          this.$emit("close")
        }
      } else {
        await this.registerStripePinpad({
          orgId: this.firebaseRefs.organization.id,
          registrationCode: this.registrationCode,
          label: this.terminalName,
        })
        this.$emit("close")
      }

      this.closeDisabled = this.awaitingResponse = false
    },
  },
}
</script>
