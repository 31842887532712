<template>
  <form-dialog
    visible
    title="Devices"
    :closeDisabled="closeDisabled"
    :awaitingResponse="awaitingResponse"
    submitLabel="Add"
    :error="mainErrorMessage"
    @close="$emit('close')"
    @submit="submitHandle()"
  >
    <v-row justify="start" class="mt-1">
      <v-col cols="4">
        <v-select
          v-model="type"
          :items="types"
          item-text="name"
          item-value="value"
          outlined
          label="Type"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field v-model.number="number" label="Number" outlined />
      </v-col>
      <v-col v-if="type === 'pos'" cols="4">
        <v-text-field
          v-model="password"
          autocomplete="off"
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          outlined
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex"
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { adminAuth } from "@/firebaseConfig"
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
import GeneralMixins from "@/AuthenticatedContent/mixins"

export default {
  name: "add-edit-form",
  components: { FormDialog },
  mixins: [GeneralMixins],
  data() {
    return {
      closeDisabled: false,
      awaitingResponse: false,
      type: "",
      number: "",
      password: "",
      types: [
        { name: "Kiosk", value: "kiosk" },
        { name: "POS", value: "pos" },
      ],
      mainErrorMessage: "",
      showPassword: false,
      user: null,
      device: null,
    }
  },
  computed: {
    ...mapState(["settings", "firebaseRefs"]),
  },
  methods: {
    ...mapActions(["updateDevices", "updateCustomers"]),
    async createUserAndDeviceObjects(payload) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          adminAuth,
          payload.email,
          payload.password
        )
        const newUser = userCredential.user
        const uid = newUser.uid

        await updateProfile(newUser, {
          displayName: `${payload.firstName} ${payload.lastName}_${this.firebaseRefs.organization.id}_readerId`,
        })

        await adminAuth.signOut()

        const user = {
          displayName: `${payload.firstName} ${payload.lastName}`,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email.toLowerCase(),
          generatedEmail: payload.email,
          phone: "1" + payload.phone,
          uid: uid,
          role: "customer",
        }

        const device = {
          type: payload.type,
          name: `${payload.firstName} ${payload.lastName}`,
          uid: uid,
        }

        this.user = user
        this.device = device
      } catch (error) {
        console.error("ERROR " + error)
        if (error.code === "auth/email-already-in-use") {
          if (payload.type === "pos") {
            this.mainErrorMessage = "POS # already in use."
          } else {
            this.mainErrorMessage = "Kiosk # already in use."
          }
        } else {
          this.mainErrorMessage = error
        }
      }
    },
    async createSquareOrStripeUser() {
      if (this.settings.paymentProvider === "square") {
        await this.createSquareUser(this.user, this.firebaseRefs.organization.id)

        this.user.role = "customer"
        this.user.stripeId = ""
        this.user.phoneVerified = true
      } else {
        const stripeUserId = await this.createStripeUser(
          this.user,
          this.firebaseRefs.organization.id
        )

        this.user.role = "customer"
        this.user.stripeId = stripeUserId
        this.user.phoneVerified = true
      }
    },

    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true

      const kioskPass = "12345678" // DEFAULT PASSWORD FOR KIOSK USED FOR FIREBASE AUTH
      const email =
        this.type +
        this.number +
        "_" +
        this.firebaseRefs.organization.id +
        "@nextgenkitchens.com"
      const firstName = this.type === "pos" ? "pos" : "kiosk"
      const lastName = this.number.toString()
      const phone = "0000000000"
      const password = this.type === "pos" ? this.password : kioskPass

      const payload = {
        type: this.type,
        number: this.number,
        password: password,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      }

      // firebaseRefs.organization.id also mapState firebaseRefs
      await this.createUserAndDeviceObjects(payload)
      await this.createSquareOrStripeUser()
      await this.updateCustomers({
        user: this.user,
        orgId: this.firebaseRefs.organization.id,
      })
      await this.updateDevices(this.device)
        .then(() => {
          this.$emit("close")
        })
        .catch(error => {
          this.mainErrorMessage = error
        })

      this.closeDisabled = this.awaitingResponse = false
    },
  },
}
</script>
