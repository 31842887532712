<template>
  <div v-if="$route.params.tab == 'devices'">
    <page-component
      pageTitle="Devices"
      :headers="headers"
      :items="filteredDevices"
      :loadingData="loading"
      :buttons="[
        {
          text: '+ Add Kiosk/POS',
          to: { params: { form: 'AddEditForm', id: 'kioskPos' } },
        },
        {
          text: '+ Add Terminal',
          to: { params: { form: 'AddEditForm', id: 'terminal' } },
        },
      ]"
    >
      <template v-slot:filters[0]>
        <custom-select
          v-model="selectedDeviceOptions"
          :items="deviceOptions"
          label="Devices"
          multiple
          dense
        />
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.type === 'terminal'">
          <v-icon
            v-if="terminalStatusTitles[item.id]"
            :color="getTerminalStatusColorForTitle(terminalStatusTitles[item.id])"
            :title="terminalStatusTitles[item.id]"
          >
            mdi-circle
          </v-icon>
          <v-progress-circular
            v-else
            indeterminate
            color="grey"
            size="20"
            width="2"
            class="mr-2"
          />
          {{ terminalStatusTitles[item.id] || "Loading status..." }}
        </div>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ formatItemType(item) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="delete" item-type="DEVICE" :item="item" />
      </template>
    </page-component>
    <kiosk-pos-form
      v-if="$route.params.form == 'AddEditForm' && $route.params.id === 'kioskPos'"
      @close="closeForm"
    />
    <terminal-form
      v-if="$route.params.form == 'AddEditForm' && $route.params.id === 'terminal'"
      @close="closeForm"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import moment from "moment"
import KioskPosForm from "./KioskPosForm.vue"
import TerminalForm from "./TerminalForm.vue"
import PageComponent from "../../shared/PageComponent.vue"
import CustomSelect from "../../shared/CustomSelect.vue"
import GeneralMixins from "@/AuthenticatedContent/mixins"
import capitalize from "@/mixins/capitalize.js"

export default {
  name: "devices",
  components: { PageComponent, KioskPosForm, TerminalForm, CustomSelect },
  mixins: [GeneralMixins, capitalize],
  data() {
    return {
      headers: [
        { text: "ID", value: "id", width: "200px" },
        { text: "Type", value: "type", width: "200px" },
        { text: "Name", value: "name", width: "300px" },
        { text: "Status", value: "status", width: "200px" },
        { text: "Actions", value: "actions" },
      ],
      terminals: [],
      terminalStatusTitles: {},
      selectedDeviceOptions: ["kiosk", "pos", "terminal"],
      deviceOptions: [
        {
          text: "Kiosk",
          value: "kiosk",
        },
        {
          text: "POS",
          value: "pos",
        },
        {
          text: "Terminal",
          value: "terminal",
        },
      ],
      pingInterval: null,
      loading: false,
      counter: 0,
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName"]),
    ...mapState(["devices", "firebaseRefs", "settings", "restaurants"]),
    filteredDevices() {
      const filteredDevices = [...this.devices, ...this.terminals]

      return filteredDevices.filter(obj =>
        this.selectedDeviceOptions.includes(obj.type)
      )
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchDevices()
    if (this.settings.paymentProvider === "square") {
      await this.fetchSquareTerminals()
    }
    await this.updateTerminals()
    this.pingInterval = setInterval(async () => {
      await this.updateTerminals()
    }, 15000)

    this.loading = false
  },
  beforeDestroy() {
    clearInterval(this.pingInterval)
  },
  methods: {
    ...mapActions(["fetchDevices"]),
    formatDate(channel) {
      return moment(channel.lastUpdated, "String").format("MMMM Do YYYY LT")
    },
    async editItem(channel) {
      this.$router.push({ params: { form: "AddEditForm", id: channel.type } })
    },
    closeForm() {
      this.$router.push({ params: { form: null, id: null }, query: {} })
    },
    formatItemType(item) {
      if (item.type === "pos") {
        return "POS"
      } else if (item.type === "kiosk") {
        return "Kiosk"
      } else if (item.type === "terminal") {
        return this.capitalize(this.settings.paymentProvider) + " Terminal"
      } else {
        console.error(
          `Unknown type: ${item.type} in ${item} is not one of kiosk, pos, squareTerminal, stripeTerminal`
        )
      }
    },
    getTerminalStatusColorForTitle(title) {
      if (title === "Online") {
        return "green"
      } else if (title === "Offline") {
        return "red"
      } else {
        return "grey"
      }
    },
    async updateTerminals() {
      switch (this.settings.paymentProvider) {
        case "stripe":
          this.fetchStripeTerminals()
          break
        case "square":
          this.pingSquareTerminal()
          break
        default:
          console.error("Unknown payment provider")
      }
    },
    async pingSquareTerminal() {
      if (!this.devices) return

      const terminalPingActionRequests = this.terminals.map(terminal => {
        return this.createSquareTerminalPingAction(terminal)
      })

      await Promise.all(terminalPingActionRequests)
        .then(terminalPings => {
          terminalPings.forEach((terminalPing, i) => {
            const terminal = this.terminals[i]
            setTimeout(() => {
              this.getSquareTerminalPingAction(terminalPing, terminal)
                .then(terminalPingResponse => {
                  terminal.status = terminalPingResponse.status
                  this.$set(
                    this.terminalStatusTitles,
                    terminal.id,
                    terminal.status === "COMPLETED" ? "Online" : "Offline"
                  )
                  this.counter++
                })
                .catch(error => {
                  console.error("Error getting terminal ping response", error)
                })
            }, 5000)
          })
        })
        .catch(error => {
          console.error("Error creating terminal ping", error)
        })
    },

    async fetchStripeTerminals() {
      const stripeReaderList = await this.getAvailableStripeReaders()

      this.terminals = stripeReaderList.map(stripeReader => {
        this.$set(
          this.terminalStatusTitles,
          stripeReader.id,
          stripeReader.status === "online" ? "Online" : "Offline"
        )
        return {
          type: "terminal",
          name: stripeReader.label,
          id: stripeReader.id,
          status: stripeReader.status,
        }
      })
    },
    async fetchSquareTerminals() {
      let squareDevicesList = []

      for (let restaurant of this.restaurants) {
        const squareDevices = await this.getAvailableSquareDevices(restaurant.id)
        if (squareDevices) {
          squareDevices.forEach(device => {
            squareDevicesList.push({
              type: "terminal",
              name: device.name,
              id: device.id,
              deviceId: device.deviceId,
              restaurantId: restaurant.id,
            })
          })
        }
      }

      this.terminals = squareDevicesList
    },
  },
}
</script>
