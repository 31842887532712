<template>
  <div>
    <v-sheet class="mx-n4 mt-n4">
      <v-tabs v-model="active" color="text" background-color="primary-darken4">
        <v-tab
          v-for="tab in availableTabs"
          :key="'tab-' + tab.name"
          :to="{ path: '/settings/' + tab.name }"
        >
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="$route.params.tab" touchless>
        <v-tab-item
          v-for="tab in availableTabs"
          :key="'tab-item-' + tab.name"
          :value="tab.name"
          class="ma-4"
        >
          <component :is="tab.component" v-if="$route.params.tab === tab.name" />
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </div>
</template>

<script>
import Settings from "./Settings"
import Restaurants from "./Restaurants"
import Loyalty from "./Loyalty"
import Users from "./Users"
import Channels from "./Channels"
import Taxes from "./Taxes"
import PromoImages from "./PromoImages"
import ThirdPartyTypes from "./ThirdPartyTypes"
import InventorySettings from "./InventorySettings.vue"
import Storages from "./Storages"
import Units from "./Units"
import Devices from "./Devices"
import { mapGetters, mapState } from "vuex"

export default {
  components: {
    Settings,
    Restaurants,
    Loyalty,
    Users,
    Taxes,
    Channels,
    PromoImages,
    ThirdPartyTypes,
    InventorySettings,
    Storages,
    Units,
    Devices,
  },
  data() {
    return {
      active: "settings",
      tabs: [
        {
          name: "settings",
          label: "Settings",
          component: "settings",
          category: "management",
        },
        {
          name: "restaurants",
          label: "Restaurants",
          component: "restaurants",
          category: "general",
        },
        {
          name: "loyalty",
          label: "Loyalty",
          component: "loyalty",
          category: "management",
        },
        {
          name: "taxes",
          label: "Taxes",
          component: "taxes",
          category: "management",
        },
        {
          name: "channels",
          label: "Payment Channels",
          component: "channels",
          category: "management",
        },
        {
          name: "users",
          label: "Users",
          component: "users",
          category: "management",
        },
        {
          name: "promoImages",
          label: "Promo Images",
          component: "promoImages",
          category: "management",
        },
        {
          name: "thirdPartyTypes",
          label: "Third Party Types",
          component: "thirdPartyTypes",
          category: "management",
        },
        {
          name: "inventorySettings",
          label: "Inventory",
          component: "inventory-settings",
          category: "inventory",
        },
        {
          name: "storages",
          label: "Storages",
          component: "storages",
          category: "inventory",
        },
        { name: "units", label: "Units", component: "units", category: "inventory" },
        {
          name: "devices",
          label: "Devices",
          component: "devices",
          category: "management", //?
        },
      ],
    }
  },
  computed: {
    ...mapState(["currentUserRole"]),
    ...mapGetters(["getSettings"]),
    inventoryEnabled() {
      return this.getSettings?.inventoryEnabled
    },
    loyaltyEnabled() {
      return this.getSettings?.loyaltyEnabled
    },
    availableTabs() {
      return this.tabs.filter(tab => {
        if (tab.name === "loyalty" && !this.loyaltyEnabled) {
          return false
        }
        if (tab.category === "inventory") {
          return this.inventoryEnabled
        } else if (tab.category === "management") {
          if (tab.name === "promoImages" && !this.getSettings?.promoImagesEnabled) {
            return false
          }
          if (tab.name === "thirdPartyTypes" && !this.getSettings?.posEnabled) {
            return false
          }
          if (tab.name === "settings" && this.currentUserRole !== "Admin") {
            return false
          }
          return (
            this.currentUserRole === "Manager" || this.currentUserRole === "Admin"
          )
        } else return true
      })
    },
  },
}
</script>
