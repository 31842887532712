<template>
  <div v-if="$route.params.tab == 'restaurants'">
    <page-component
      pageTitle="Restaurants"
      :headers="headers"
      :items="filteredRestaurants"
    >
      <template v-if="currentUserRole === 'Admin'" v-slot:buttons>
        <v-btn
          color="primary"
          @click="
            $router.push({
              params: { form: 'AddEditForm' },
            })
          "
        >
          + Add New Restaurant
        </v-btn>
        <v-btn class="mr-2" color="primary" @click="addLinkSquare">
          + Add Square Restaurant
        </v-btn>
      </template>
      <template v-if="currentUserRole === 'Admin'" v-slot:filters[0]>
        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="includeDeleted"
              label="Show deleted"
              hide-details
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox v-model="includeTest" label="Show test" hide-details dense />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
        <v-chip
          v-if="item.isTest"
          title="This is a test restaurant."
          color="orange"
          small
          text-color="white"
          class="ml-1"
        >
          Test
        </v-chip>
      </template>
      <template v-slot:[`item.address`]="{ item }">
        {{ formatAddress(item) }}
      </template>
      <template v-slot:[`item.cuisines`]="{ item }">
        {{ item.cuisines && item.cuisines.join(" / ") }}
      </template>
      <template v-slot:[`item.isSnoozed`]="{ item }">
        <div v-if="item.isDeleted">Deleted</div>
        <nxg-snooze-btn v-else type="restaurant" :item="item" show-time />
      </template>
      <template v-slot:[`item.prepTime`]="props">
        {{ props.item.prepTime + " min" }}
      </template>
      <template v-if="userCanEdit" v-slot:[`item.actions`]="{ item }">
        <!-- <nxg-action-btn
          v-if="importPossible(item.id)"
          type="sync"
          title="Sync menu items from square"
          @click="importMenuItemsFromSquare(item)"
        /> -->
        <nxg-action-btn
          v-if="alreadyLinkedToSquare(item.id) && !item.isDeleted"
          type="sync"
          title="Sync everything from square"
          @click="importAllFromSquare(item)"
        />
        <nxg-action-btn
          v-else-if="!item.isDeleted"
          type="link"
          title="Connect this restaurant to a Square account"
          @click="linkSquare(item)"
        />
        <nxg-action-btn
          v-if="!item.isDeleted"
          type="edit"
          @click="editRestaurant(item)"
        />
        <nxg-action-btn
          v-if="!item.isDeleted && currentUserRole === 'Admin'"
          type="delete"
          item-type="RESTAURANT"
          :item="item"
        />
      </template>
    </page-component>
    <add-edit-form
      v-if="$route.params.form == 'AddEditForm'"
      @close="
        $router.push({
          params: { form: null, id: null },
        })
        fetchRestaurants()
      "
    />
    <sync-dialog
      ref="syncDialog"
      v-model="restaurantToSync"
      :needsRefresh="needsRefresh"
      :needsRefreshNew="needsRefreshNew"
      @needsRefresh="needsRefresh = $event"
      @needsRefreshNew="needsRefreshNew = $event"
    />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import SyncDialog from "@/AuthenticatedContent/shared/dialogs/SyncDialog.vue"
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"
import NxgSnoozeBtn from "@/AuthenticatedContent/shared/SnoozeButton.vue"
import syncMixins from "@/AuthenticatedContent/shared/mixins/syncMixins.js"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"
import { mapGetters, mapState, mapActions } from "vuex"
import "moment-timezone"

export default {
  name: "restaurants",
  components: {
    AddEditForm,
    SyncDialog,
    PageComponent,
    NxgSnoozeBtn,
  },
  mixins: [syncMixins, topLevelMixins],
  data() {
    return {
      includeDeleted: false,
      includeTest: true,
    }
  },
  computed: {
    ...mapGetters(["userCanEdit"]),
    ...mapState(["currentUserRole", "restaurants", "firebaseRefs", "channels"]),
    headers() {
      let headers = [
        { type: "checkbox", sortable: false, class: "header-style" },
        { text: "Name", value: "name", class: "header-style" },
        { text: "Address", value: "address", class: "header-style" },
        { text: "Cuisines", value: "cuisines", class: "header-style" },
        {
          text: "Status",
          value: "isSnoozed",
          class: "header-style",
        },
        { text: "Prep Time", value: "prepTime", class: "header-style" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "header-style",
        },
      ]
      if (!["Admin", "Manager"].includes(this.currentUserRole)) {
        headers = headers.filter(header => header.value !== "actions")
      }
      return headers
    },
    filteredRestaurants() {
      return this.restaurants.filter(
        restaurant =>
          (this.includeDeleted || !restaurant.isDeleted) &&
          (this.includeTest || !restaurant.isTest)
      )
    },
  },
  methods: {
    ...mapActions(["fetchCuisines", "fetchRestaurants", "updateDeliveryStatus"]),
    formatAddress(restaurant) {
      if (restaurant.hasOwnProperty("address")) {
        return this.convertAddressObjectToString(restaurant.address)
      } else {
        return "N/A"
      }
    },
    async editRestaurant(restaurant) {
      await this.$store.commit("setItemToEdit", restaurant)
      this.$router.push({ params: { form: "AddEditForm", id: restaurant.id } })
    },
    importAllFromSquare(restaurant) {
      this.$refs.syncDialog.importAllFromSquare(restaurant)
    },
  },
}
</script>

<style></style>
